export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getRandom = (min, scale) => min + Math.random() * scale;

export const getClient = event => ({
  x: event.targetTouches ? event.targetTouches[0].clientX : event.clientX,
  y: event.targetTouches ? event.targetTouches[0].clientY : event.clientY,
});

export const debounce = (func, context, delay = 0) => {
  let timeout;

  return (...args) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(
      () => requestAnimationFrame(func.bind(context, ...args)),
      delay
    );
  };
};

export const invertColors = imageData => {
  const data = new Uint8ClampedArray(imageData.data);
  const { length } = data;

  for (let i = 0; i < length; i += 4) {
    if (data[i + 3]) {
      data[i] = data[i] ^ 255;
      data[i + 1] = data[i + 1] ^ 255;
      data[i + 2] = data[i + 2] ^ 255;
    }
  }

  return new ImageData(data, imageData.width, imageData.height);
};

export const desaturateColors = imageData => {
  const data = new Uint8ClampedArray(imageData.data);
  const { length } = data;
  const value = -200 / 255;

  for (let i = 0; i < length; i += 4) {
    if (data[i + 3]) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const gray = r * 0.2126 + g * 0.7152 + b * 0.0722;

      data[i] += (r - gray) * value;
      data[i + 1] += (g - gray) * value;
      data[i + 2] += (b - gray) * value;
    }
  }

  return new ImageData(data, imageData.width, imageData.height);
};
