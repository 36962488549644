import { shuffleArray } from './utils';

const blogs = shuffleArray([
  'miuccias',
  'formschon',
  'justinboyer',
  'jennifer-is-me',
  'revolttv',
  'justdropithere',
  'diaryinthesnow',
  'ilverde',
  'photographicpictures',
  'delinquentgentleman',
  'trend-spotting',
  'fakemargiela',
  'fakemargiela',
  'form-space-and-order',
  'modernstyleposts',
  'only1copy',
  'release010',
  'erisblackclothing',
  'infinite-movement',
  'leflambeur',
  'midnight-charm',
  'athingcalledbliss',
  'parkerandloulou',
  'ibbyfashion',
  'wherethespiritmeetstheboneposts',
  'nellatanadelleone',
  'trustyourblood.com',
  'atmuse',
  'rowdyism',
  'reclusiveundgrnd',
  'surflocos',
  'crystalizedcontrol',
  'elisjolie',
]);

const doodlesCount = 45;
const doodleIndexes = shuffleArray([...Array(doodlesCount).keys()]);
const doodlesSpeed = 0.75;
const doodleMaxSize = 800;

const artsCount = 19;
const artIndexes = shuffleArray([...Array(artsCount).keys()]);
const artsSpeed = 1.2;

export default {
  blogs,

  doodlesCount,
  doodleIndexes,
  doodlesSpeed,
  doodleMaxSize,

  artsCount,
  artIndexes,
  artsSpeed,
};
