import 'regenerator-runtime/runtime';
import Photos from './photos';
import Doodles from './doodles';
import Arts from './arts';

let photos = null;
let doodles = null;
let arts = null;

window.addEventListener('resize', () => {
  onResize();
});
window.addEventListener('load', () => {
  const main = document.querySelector('main');
  const magicButton = document.querySelector('button.invert');

  magicButton.addEventListener('click', () => {
    main.classList.toggle('inverted');
    photos.invert();
    doodles.invert();
    arts.invert();
  });

  const info = document.querySelector('.info');
  const whyButton = document.querySelector('button.why');
  const closeButton = document.querySelector('button.close');

  whyButton.addEventListener('click', () => {
    info.classList.add('visible');
  });
  closeButton.addEventListener('click', () => {
    info.classList.remove('visible');
  });

  init();
  photos.load();
  doodles.load();
  arts.load();
});

const onResize = () => {
  photos.resize();
  doodles.resize();
  arts.resize();
};

const onViewPortChanged = viewport => {
  photos.changeViewport(viewport);
  doodles.changeViewport(viewport);
  arts.changeViewport(viewport);
};

const init = () => {
  photos = new Photos(document.querySelector('canvas.pics'), onViewPortChanged);
  doodles = new Doodles(document.querySelector('canvas.doodles'));
  arts = new Arts(document.querySelector('canvas.arts'));
};
