import Pictures from './pictures';
import { getRandom, invertColors } from './utils';
import constants from './constants';

class Arts extends Pictures {
  constructor(canvas) {
    super(canvas);
    this.speed = constants.artsSpeed;

    this.indexes = constants.artIndexes;
    this.index = 0;
  }

  load() {
    const count = this.createCells();

    [...Array(count).keys()].forEach(index => {
      this.index = this.index + 1 === constants.artsCount ? 0 : this.index + 1;
      this.pictures.push({
        index,
        url: `/arts/${this.indexes[this.index] + 1}.svg`,
      });
    });

    this.position();
    this.render();
  }

  position() {
    const width = this.grid.width * 0.5;
    const height = this.grid.height * 0.5;

    this.cells.forEach(({ x, y, index }) => {
      if (!this.pictures[index] || this.pictures[index].init) {
        return;
      }

      this.pictures[index].init = true;
      this.pictures[index].resize = getRandom(0.8, 1);
      this.pictures[index].x0 = x;
      this.pictures[index].y0 = y;

      this.pictures[index].x = x + getRandom(-width, width * 2);
      this.pictures[index].y = y + getRandom(-height, height * 2);
    });
  }

  render() {
    this.context.clearRect(0, 0, this.size.width, this.size.height);

    this.cells.forEach(({ index }) => {
      const art = this.pictures[index];
      if (!art) {
        return;
      }

      const { image, loaded } = art;
      const x = art.x - this.viewport.x * this.speed;
      const y = art.y - this.viewport.y * this.speed;

      if (
        art.loaded &&
        (x + art.width < 0 || x > this.viewport.width) &&
        (y + art.height < 0 || y > this.viewport.height)
      ) {
        return;
      }

      if (image) {
        if (this.inverted) {
          this.context.putImageData(art.inverted, x, y);
        } else {
          this.context.drawImage(image, x, y, art.width, art.height);
        }
      } else if (!loaded) {
        art.loaded = true;

        const img = new Image();
        img.src = `${art.url}`;
        img.onload = () => {
          art.image = img;
          this.resizeArt(art, img.width, img.height);

          this.dummyContext.drawImage(img, 0, 0, art.width, art.height);
          art.inverted = invertColors(
            this.dummyContext.getImageData(0, 0, art.width, art.height)
          );
          this.dummyContext.clearRect(0, 0, art.width, art.height);

          const x = art.x - this.viewport.x * this.speed;
          const y = art.y - this.viewport.y * this.speed;

          if (this.inverted) {
            this.context.putImageData(art.inverted, x, y);
          } else {
            this.context.drawImage(img, x, y, art.width, art.height);
          }
        };
      }
    });
  }

  resizeGrid() {
    const { width, height } = this.size;
    const widthCount = this.getGridSize(width);
    const heightCount = this.getGridSize(height);

    this.grid = {
      width: width / widthCount,
      height: height / heightCount,
    };
  }

  getGridSize(screenSize) {
    if (screenSize > 1920) {
      return 3;
    }
    if (screenSize > 768) {
      return 2;
    }
    return 1;
  }

  resizeArt(art, width, height) {
    art.width = width * art.resize;
    art.height = height * art.resize;

    const coeff = 1.2;

    if (art.width > this.grid.width * coeff) {
      art.width = this.grid.width * coeff;
      art.height = (height * art.width) / width;
    }
    if (art.height > this.grid.height * coeff) {
      art.height = this.grid.height * coeff;
      art.width = (width * art.height) / height;
    }
  }
}

export default Arts;
