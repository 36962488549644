class Pictures {
  constructor(canvas) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d');
    this.inverted = false;

    this.pictures = [];
    this.speed = 1;

    this.cells = [];

    this.init();

    this.dummyCanvas = document.createElement('canvas');
    this.dummyCanvas.classList.add('hidden');
    this.dummyCanvas.width = this.size.width * 2;
    this.dummyCanvas.height = this.size.height * 2;
    this.dummyContext = this.dummyCanvas.getContext('2d');
  }

  createCells() {
    const xmin = this.viewport.x
      ? Math.floor((this.viewport.x * this.speed) / this.grid.width) - 1
      : 0;
    const ymin = this.viewport.y
      ? Math.floor((this.viewport.y * this.speed) / this.grid.height) - 1
      : 0;

    const xbase = Math.ceil(this.viewport.width / this.grid.width) + 3;
    const ybase = Math.ceil(this.viewport.width / this.grid.width) + 3;

    this.cells = [];
    let count = 0;
    [...Array(xbase).keys()].forEach(row => {
      [...Array(ybase).keys()].forEach(col => {
        const x = (xmin + row) * this.grid.width;
        const y = (ymin + col) * this.grid.height;

        const photo = this.pictures.findIndex(ph => ph.x0 === x && ph.y0 === y);

        this.cells.push({
          x,
          y,
          index: photo === -1 ? this.pictures.length + count : photo,
        });

        if (photo === -1) {
          count += 1;
        }
      });
    });

    return count;
  }

  invert() {
    this.inverted = !this.inverted;
    this.render();
  }

  changeViewport(vp) {
    this.viewport = vp;
    this.load();
  }

  resize() {
    this.init();
    this.load();
  }

  init() {
    this.size = {
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight,
    };
    this.viewport = {
      x: -this.size.width * 0.01,
      y: -this.size.width * 0.01,
      width: this.size.width,
      height: this.size.height,
    };

    this.resizeCanvas();
    this.resizeGrid();
  }

  resizeCanvas() {
    this.canvas.setAttribute('width', this.size.width);
    this.canvas.setAttribute('height', this.size.height);
  }
}

export default Pictures;
