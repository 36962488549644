import Pictures from './pictures';
import { getRandom, invertColors } from './utils';
import constants from './constants';

class Doodles extends Pictures {
  constructor(canvas) {
    super(canvas);
    this.speed = constants.doodlesSpeed;

    this.indexes = constants.doodleIndexes;
    this.index = 0;
  }

  load() {
    const count = this.createCells();

    [...Array(count).keys()].forEach(index => {
      this.index =
        this.index + 1 === constants.doodlesCount ? 0 : this.index + 1;
      this.pictures.push({
        index,
        url: `/doodles/doodle-${this.indexes[this.index] + 1}.png`,
      });
    });

    this.position();
    this.render();
  }

  position() {
    const width = this.grid.width * 0.16;
    const height = this.grid.width * 0.16;

    this.cells.forEach(({ x, y, index }) => {
      if (!this.pictures[index] || this.pictures[index].init) {
        return;
      }

      this.pictures[index].init = true;
      this.pictures[index].x0 = x;
      this.pictures[index].y0 = y;

      this.pictures[index].x = x + getRandom(-width, width * 2);
      this.pictures[index].y = y + getRandom(-height, height * 2);
    });
  }

  render() {
    this.context.clearRect(0, 0, this.size.width, this.size.height);

    this.cells.forEach(({ index }) => {
      const doodle = this.pictures[index];
      if (!doodle) {
        return;
      }

      const { image, loaded } = doodle;
      const x = doodle.x - this.viewport.x * this.speed;
      const y = doodle.y - this.viewport.y * this.speed;

      if (
        doodle.loaded &&
        (x + doodle.width < 0 || x > this.viewport.width) &&
        (y + doodle.height < 0 || y > this.viewport.height)
      ) {
        return;
      }

      if (image) {
        if (this.inverted) {
          this.context.putImageData(doodle.inverted, x, y);
        } else {
          this.context.drawImage(image, x, y, doodle.width, doodle.height);
        }
      } else if (!loaded) {
        doodle.loaded = true;

        const img = new Image();
        img.src = `${doodle.url}`;
        img.onload = () => {
          doodle.image = img;
          this.resizeDoodle(doodle, img.width, img.height);

          this.dummyContext.drawImage(img, 0, 0, doodle.width, doodle.height);
          doodle.inverted = invertColors(
            this.dummyContext.getImageData(0, 0, doodle.width, doodle.height)
          );
          this.dummyContext.clearRect(0, 0, doodle.width, doodle.height);

          const x = doodle.x - this.viewport.x * this.speed;
          const y = doodle.y - this.viewport.y * this.speed;

          if (this.inverted) {
            this.context.putImageData(doodle.inverted, x, y);
          } else {
            this.context.drawImage(img, x, y, doodle.width, doodle.height);
          }
        };
      }
    });
  }

  resizeGrid() {
    const { width, height } = this.size;

    let widthCount = 0;
    let heightCount = 0;

    if (width > height) {
      widthCount = this.getGridSize(width);
      heightCount = 1;
    } else {
      heightCount = this.getGridSize(height);
      widthCount = 1;
    }

    this.grid = {
      width: width / widthCount,
      height: height / heightCount,
    };
  }

  getGridSize(screenSize) {
    if (screenSize > 1920) {
      return 3;
    }
    if (screenSize > 768) {
      return 2;
    }
    return 1;
  }

  resizeDoodle(doodle, width, height) {
    doodle.height = Math.min(height, constants.doodleMaxSize);
    doodle.width = (width * doodle.height) / height;

    if (doodle.width > this.grid.width) {
      doodle.width = this.grid.width;
      doodle.height = (height * doodle.width) / width;
    }
    if (doodle.height > this.grid.height) {
      doodle.height = this.grid.height;
      doodle.width = (width * doodle.height) / height;
    }
  }
}

export default Doodles;
