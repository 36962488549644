import { shuffleArray, getRandom } from './utils';

const postUrl = 'https://api.tumblr.com/v2/blog/';

const keys = [
  'qERU7FQgk2qe5cVA76WWbyWeUpvC4PqaWehCj990ARTmQpjChE',
  'A4b9s6Xg6W3XIVq2olGqnEVM5Ec6WklTVb49dVBSOEXuR3YiO5',
  'CPY40kpXP2hmTeGTTJK3tOlUn8LQyyhwkLLMHyuJQPJYUnEldg',
  'F8vgA5I9SFdJDAzsa1giPiXROoqraC5RMSAD96I4XWMrgQLA0O',
  'FzM9aY5HsBiofdF4ptpxUeyRqjRMYUGaNMMwTC15yzVdjQWSlT',
];
const apiKey = keys[new Date().getMinutes() % keys.length];

const fetchBlog = async (name, offset = 0) => {
  const data = await fetch(
    `${postUrl}${name}/posts/photo?api_key=${apiKey}&offset=${offset}`
  );
  const { response } = await data.json();

  return response && response.posts ? response.posts : [];
};

export const getBlogPosts = async (blogName, count, posts = []) => {
  let offset = Math.floor(getRandom(0, 200));
  let length = posts.length;

  while (length < count) {
    const blogPosts = await fetchBlog(blogName, offset);

    blogPosts
      .filter(({ photos }) => photos && photos.length)
      .forEach(({ photos }) => {
        const photo = photos[0];
        posts.push(
          photo.alt_sizes.length > 1 ? photo.alt_sizes[1] : photo.original_size
        );
      });

    if (posts.length === length) {
      return [];
    }
    length = posts.length;
    offset += 20;
  }

  if (length >= count) {
    return shuffleArray(posts);
  }
};
