import Pictures from './pictures';
import {
  debounce,
  getRandom,
  desaturateColors,
  invertColors,
  getClient,
} from './utils';
import { getBlogPosts } from './tumblr';
import constants from './constants';

class Photos extends Pictures {
  constructor(canvas, callback) {
    super(canvas);

    this.callback = callback;

    this.onStartDrag = this.onStartDrag.bind(this);
    this.onDrag = debounce(this.onDrag, this);
    this.onScroll = debounce(this.onScroll, this);
    this.onStopDrag = this.onStopDrag.bind(this);

    this.canvas.addEventListener('mousedown', this.onStartDrag);
    this.canvas.addEventListener('touchstart', this.onStartDrag);

    this.canvas.addEventListener('mousemove', this.onDrag);
    this.canvas.addEventListener('touchmove', this.onDrag);

    this.canvas.addEventListener('mouseup', this.onStopDrag);
    this.canvas.addEventListener('mouseleave', this.onStopDrag);
    this.canvas.addEventListener('touchend', this.onStopDrag);

    this.canvas.addEventListener('mousewheel', this.onScroll, false);

    this.dragging = false;
    this.currentPos = null;

    this.blogIndex = 0;
    this.freePhotos = [];
  }

  async load() {
    const count = this.createCells();

    if (!this.loading) {
      this.loading = true;

      const posts = await getBlogPosts(
        constants.blogs[this.blogIndex],
        count,
        this.freePhotos
      );

      this.blogIndex =
        this.blogIndex < constants.blogs.length - 1 ? this.blogIndex + 1 : 0;

      if (count && posts.length === 0) {
        this.loading = false;
        this.load();
        return;
      }

      this.freePhotos = posts;
      this.freePhotos.splice(0, count).forEach(photo => {
        this.pictures.push({
          originalWidth: photo.width,
          originalHeight: photo.height,
          url: photo.url,
          init: false,
        });
      });

      this.loading = false;
    }

    this.position();
    this.render();
  }

  position() {
    const width = this.grid.width * 0.5;
    const height = this.grid.height * 0.5;

    this.cells.forEach(({ x, y, index }) => {
      if (!this.pictures[index] || this.pictures[index].init) {
        return;
      }

      this.pictures[index].init = true;
      this.pictures[index].x0 = x;
      this.pictures[index].y0 = y;

      this.pictures[index].x = x + getRandom(-width * 0.5, width);
      this.pictures[index].y = y + getRandom(-height * 0.5, height);

      this.resizePhoto(this.pictures[index]);
    });
  }

  render() {
    this.context.clearRect(0, 0, this.size.width, this.size.height);

    this.cells.forEach(({ index }) => {
      const photo = this.pictures[index];
      if (!photo) {
        return;
      }

      const { image, loaded } = photo;
      const x = photo.x - this.viewport.x * this.speed;
      const y = photo.y - this.viewport.y * this.speed;

      if (
        (photo.x + photo.width < this.viewport.x ||
          photo.x > this.viewport.x + this.viewport.width) &&
        (photo.y + photo.height < this.viewport.y ||
          photo.y > this.viewport.y + this.viewport.height)
      ) {
        return;
      }

      this.context.fillRect(x, y, photo.width, photo.height);

      if (image) {
        if (this.inverted) {
          this.context.putImageData(photo.inverted, x, y);
        } else {
          this.context.putImageData(photo.desaturated, x, y);
        }
      } else if (!loaded) {
        photo.loaded = true;

        const img = new Image();
        img.setAttribute('crossOrigin', '');
        img.src = `${photo.url}?${new Date().getTime()}`;
        img.onload = () => {
          photo.image = img;
          this.dummyContext.drawImage(img, 0, 0, photo.width, photo.height);

          const imageData = this.dummyContext.getImageData(
            0,
            0,
            photo.width,
            photo.height
          );

          this.dummyContext.clearRect(0, 0, photo.width, photo.height);
          photo.inverted = invertColors(imageData);
          photo.desaturated = desaturateColors(imageData);

          const x = photo.x - this.viewport.x * this.speed;
          const y = photo.y - this.viewport.y * this.speed;

          if (this.inverted) {
            this.context.putImageData(photo.inverted, x, y);
          } else {
            this.context.putImageData(photo.desaturated, x, y);
          }
        };
      }
    });
  }

  onStartDrag(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragging = true;
    this.currentPos = getClient(event);
  }

  onDrag(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.dragging) {
      return;
    }

    const mousePos = getClient(event);
    const viewport = {
      x: this.viewport.x - mousePos.x + this.currentPos.x,
      y: this.viewport.y - mousePos.y + this.currentPos.y,
      width: this.viewport.width,
      height: this.viewport.height,
    };

    this.currentPos = { ...mousePos };
    this.callback(viewport);
  }

  onScroll(event) {
    event.preventDefault();
    event.stopPropagation();

    const viewport = {
      x: this.viewport.x + event.deltaX,
      y: this.viewport.y + event.deltaY,
      width: this.viewport.width,
      height: this.viewport.height,
    };

    this.callback(viewport);

    clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(() => {
      this.callback(this.viewport);
    }, 100);
  }

  onStopDrag() {
    this.dragging = false;
    this.currentPos = null;
  }

  resizeCanvas() {
    super.resizeCanvas();
    this.context.fillStyle = 'rgba(80, 80, 80, 0.2)';
  }

  resizeGrid() {
    const { width, height } = this.size;
    const screenAspectRatio = height / width;

    let widthCount = 0;
    let heightCount = 0;

    if (width > height) {
      widthCount = this.getGridSize(width);
      heightCount = Math.max(Math.floor(widthCount * screenAspectRatio), 2);
    } else {
      heightCount = this.getGridSize(height);
      widthCount = Math.max(Math.floor(heightCount / screenAspectRatio), 2);
    }

    this.grid = {
      width: width / widthCount,
      height: height / heightCount,
    };
  }

  getGridSize(screenSize) {
    if (screenSize > 2800) {
      return 7;
    }
    if (screenSize > 1920) {
      return 6;
    }
    if (screenSize > 1440) {
      return 5;
    }
    if (screenSize > 1024) {
      return 4;
    }
    return 3;
  }

  resizePhoto(photo) {
    const coeff = getRandom(0.7, 0.3);
    const maxWidth = this.grid.width * coeff;
    const maxHeight = this.grid.height * coeff;

    const { originalWidth, originalHeight } = photo;

    if (originalWidth > originalHeight) {
      photo.width = maxWidth;
      photo.height = (originalHeight * maxWidth) / originalWidth;
    } else {
      photo.height = maxHeight;
      photo.width = (originalWidth * maxHeight) / originalHeight;
    }
  }
}

export default Photos;
